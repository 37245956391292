import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Typography from "../components/Typography";
import Grid from "@mui/material/Grid";
import MinaeImage from "../../assets/minae.JPG";
import KaiImage from "../../assets/inagaki_kai.jpg";
import YuseiImage from "../../assets/naito.jpg";

const career_rows_minae = [
  { year: 1997, border: true, text: "兵庫県立神出学園　生活指導員（3年）" },
  { year: 1998, border: true, text: "愛知教育大学大学院教育課程" },
  { year: 2001, border: true, text: "	豊橋市立東陽中学校非常勤講師（1年)" },
  { year: 2004, border: true, text: "愛知大学非常勤講師（2年）" },
  { year: 2005, border: true, text: "株式会社鈴木時計舗（16年）" },
  { year: 2006, border: true, text: "愛知教育大学非常勤講師（5年）" },
  { year: null, border: true, text: "トヨタ看護専門学校非常勤講師（15年継続中）" },
  { year: 2019, border: true, text: "SW豊田「学生ギルド」でチーム優勝" },
  { year: 2020, border: true, text: "合同会社学生ギルド設立" },
];

const career_rows_kai = [
  { year: 2016, border: true, text: "フィンランド留学" },
  { year: 2018, border: true, text: "NHK高専ロボコン豊田高専Aチーム代表" },
  { year: null, border: true, text: "FRCチーム冷奴立ち上げ・ハワイ大会出場" },
  { year: 2019, border: true, text: "ロボカップジャパンオープン2019ながおか Small Size League 3位" },
  { year: null, border: true, text: "スタートアップウィークエンド豊田「学生ギルド」優勝チーム代表" },
  { year: 2020, border: true, text: "合同会社学生ギルド設立" },
  { year: 2021, border: true, text: "豊田工業高等専門学校　電気・電子システム工学科卒業" },
  { year: 2023, border: true, text: "東京農工大学 工学部卒業" },
  { year: null, border: true, text: "奈良先端科学技術大学院大学 先端科学技術研究科入学" },
];

const career_rows_yusei = [
  { year: 2017, border: true, text: "高専生向けNI myRIO組み込みシステム開発コンテスト 2017 優秀賞" },
  { year: 2018, border: true, text: "RoboCup 2018 Montreal Small Size League 9th" },
  { year: 2019, border: false, text: "RoboCup 2019 Sydney Small Size League 8th" },
  { year: null, border: true, text: "ロボカップジャパンオープン2019ながおか Small Size League 3位" },
  { year: 2020, border: false, text: "電気学会関西支部 令和元年度高専卒業研究発表会 論文発表賞" },
  { year: null, border: true, text: "豊田工業高等専門学校 電気・電子システム工学科卒業" },
  { year: 2021, border: false, text: "ロボカップ研究賞 Study of Decision-Making Algorithm using Monte Carlo Tree Search for RoboCup Soccer Small Size Robots 特定非営利活動法人 ロボカップ日本委員会" },
  { year: null, border: false, text: "RoboCup 2021 Small Size League Virtual Tournament 5th, Hardware Challenge 4th" },
  { year: null, border: false, text: "RoboCup Asia-Pacific 2021 Small Size League 3rd" },
  { year: null, border: true, text: "日本ロボット学会賞 RoboCup Asia-Pacific 2021 一般社団法人日本ロボット学会 " },
  { year: 2022, border: false, text: "豊田工業高等専門学校 専攻科電子機械工学専攻修了" },
  { year: null, border: true, text: "奈良先端科学技術大学院大学 先端科学技術研究科入学" },
];

function Members() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p id="Member" />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          MEMBERS
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
            <Box>
              <Box width="100%" style={{ margin: "auto" }}>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  代表社員
                </Typography>
                <Typography variant="h4" mb={2}>
                  鈴木 美苗
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  トヨタ看護専門学校 非常勤講師
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  修士（教育学）（愛知教育大学）
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  component="a"
                  href="https://suzukiminae.gakuseiguild.co.jp"
                  sx={{ mt: 2 }}
                  startIcon={<HomeIcon />}
                >
                  鈴木美苗公式ウェブサイト
                </Button>
                <Table size="small" sx={{mt: 3}}>
                  <TableBody>
                    {career_rows_minae.map((row) => {
                      return (
                        <TableRow key={row.text}>
                          <TableCell align="left" style={{ border: 0, width: "1px", whiteSpace: "nowrap" }}>{row.year}</TableCell>
                          <TableCell align="left">{row.text}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} mt={3} order={{ xs: 1, sm: 2 }}>
            <div style={{ textAlign: "center" }}>
              <Avatar src={MinaeImage} style={{ width: "80%", height: "80%", margin: "0 auto" }} />
            </div>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ mb: 3, mt: 3 }} style={{ width: "100%" }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
            <Box>
              <Box width="100%" style={{ margin: "auto" }}>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  エンジニア
                </Typography>
                <Typography variant="h4" mb={2}>
                  稲垣 海
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  奈良先端科学技術大学院大学 先端科学技術研究科
                </Typography>
                <Table size="small" sx={{mt: 3}}>
                  <TableBody>
                    {career_rows_kai.map((row) => {
                      return (
                        <TableRow key={row.text}>
                          <TableCell align="left" style={{ border: 0, width: "1px", whiteSpace: "nowrap" }}>{row.year}</TableCell>
                          <TableCell align="left">{row.text}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} mt={3} order={{ xs: 1, sm: 2 }}>
            <div style={{ textAlign: "center" }}>
              <Avatar src={KaiImage} style={{ width: "80%", height: "80%", margin: "0 auto" }} />
            </div>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ mb: 3, mt: 3 }} style={{ width: "100%" }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
            <Box>
              <Box width="100%" style={{ margin: "auto" }}>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  エンジニア
                </Typography>
                <Typography variant="h4" mb={2}>
                  内藤 優星
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  奈良先端科学技術大学院大学 先端科学技術研究科
                </Typography>
                <Table size="small" sx={{mt: 3}}>
                  <TableBody>
                    {career_rows_yusei.map((row) => {
                      return (
                        <TableRow key={row.text}>
                          <TableCell align="left" style={{ border: 0, width: "1px", whiteSpace: "nowrap" }}>{row.year}</TableCell>
                          <TableCell align="left">{row.text}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} mt={3} order={{ xs: 1, sm: 2 }}>
            <div style={{ textAlign: "center" }}>
              <Avatar src={YuseiImage} style={{ width: "80%", height: "80%", margin: "0 auto" }} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Members;
