import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";

class Wave {
  constructor(canvasList, colorList) {
    this.unit = 100;
    this.canvasList = canvasList; // キャンバスの配列
    this.info = {};// 全キャンバス共通の描画情報
    this.colorList = colorList; // 各キャンバスの色情報
  }

  setWave(canvasList, colorList) {
    this.canvasList = canvasList; // キャンバスの配列
    this.colorList = colorList; // 各キャンバスの色情報
  }

  init() {
    this.info.t = 0;
    this.canvasList = [];
    this.colorList = [];
  }

  draw(canvas, color, seconds) {
    this.info.t = seconds * Math.PI;
    let context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);

    const delay = canvas.width / 2;
    this.drawWave(canvas, color[0], 0.5, 2, delay);
    this.drawWave(canvas, color[1], 0.5, 2.5, delay);
    this.drawWave(canvas, color[2], 0.5, 3, delay);
    this.drawWave(canvas, color[3], 0.5, 3.5, delay);
    this.drawWave(canvas, color[4], 0.5, 4, delay);
  }

  drawWave(canvas, color, alpha, zoom, delay) {
    let context = canvas.getContext('2d');
    context.strokeStyle = color;
    context.lineWidth = 7;//線の幅
    context.globalAlpha = alpha;
    context.beginPath();
    this.drawSine(canvas, this.info.t * 0.5, zoom, delay);
    context.stroke();
  }

  drawSine(canvas, t, zoom, delay) {
    let xAxis = Math.floor(canvas.height / 2);
    let yAxis = 0;
    let context = canvas.getContext('2d');
    // Set the initial x and y, starting at 0,0 and translating to the origin on
    // the canvas.
    const coef = 0.7;
    let x = t; //時間を横の位置とする
    let y = Math.sin(x - delay) * coef;
    context.moveTo(yAxis, this.unit * y + xAxis); //スタート位置にパスを置く

    // Loop to draw segments (横幅の分、波を描画)
    for (let i = yAxis; i <= canvas.width + 10; i += 10) {
      x = t + (-yAxis + i) / this.unit / zoom;
      y = Math.sin(x - delay) * coef;
      context.lineTo(i, this.unit * y + xAxis);
    }
  }
}

const TopLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  minHeight: window.innerHeight,
}));

function TopLayout(props) {
  const { children } = props;
  const [png, setPng] = useState(null);
  const [count, setCount] = useState(0);
  const [seconds, setSeconds] = useState(0.0);
  const wave = new Wave();
  wave.init();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      const width = window.innerWidth;
      const height = window.innerHeight;
      let canvasElem = document.createElement('canvas');
      canvasElem.width = width;
      canvasElem.height = height;
      const ctx = canvasElem.getContext('2d');
      canvasElem.contextCache = ctx;

      ctx.clearRect(0, 0, width, height);

      setSeconds(seconds + 0.016);
      wave.draw(canvasElem, ['#ff0000', '#7f00ff', '#00ffff', '#7fff00', '#ffff00'], seconds);

      setPng(canvasElem.toDataURL());
    }, 16);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [count, seconds]);

  return (
    <div>
      <TopLayoutRoot>
        <Container
          sx={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Collapse in={seconds > 0.1}>
            <div>
              {children}
            </div>
          </Collapse>
          <span style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, zIndex: -2 }}>
            <img alt="icon" src={png} style={{ width: "100%" }} />
          </span>
        </Container>
      </TopLayoutRoot>
    </div>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default TopLayout;
