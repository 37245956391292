import * as React from "react";
import { Fragment } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Zoom from "@mui/material/Zoom";

import Typography from "../components/Typography";
import ProfileImage from "../../assets/Profile.JPG"
import StreamImage from "../../assets/robot.jpg"
import FestivaleImage from "../../assets/toyota_festivale.png"
import VCTImage from "../../assets/VCT.png"
import Stream2Image from "../../assets/stream.png"

import MiraiGomibakoCoverImage from "../../assets/cocomo_grandprix.jpg"
import MiraiGomibakoAbstImage from "../../assets/miraigomibako_abstract.jpg"
import monacaCoverImage from "../../assets/monaca_device.png"
import monacaAbstImage from "../../assets/monaca_abstract.jpg"
import StreamCoverImage from "../../assets/stream_cover.jpg"
import StreamAbstImage from "../../assets/stream.png"
import StreamLabImage from "../../assets/streamlab.png"
import StreamLabGif from "../../assets/streamlab.gif"
import OtherImage from "../../assets/other.png"

function WorkCard(props) {
  const root_style = { display: "flex", width: "100%", height: "12em" };
  const style = props.style === null ? root_style : Object.assign(root_style, props.style);

  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Card onClick={() => setOpen(true)} raised={true} style={style}>
        <CardActionArea style={{ display: "flex", justifyContent: "left" }}>
          <CardContent align="left" style={{ flex: "1 0 50%" }}>
            <Typography variant="h5">{props.title}</Typography>
            <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
          </CardContent>
          <CardMedia component="img" style={{ minWidth: "50%", minHeight: "100%" }} src={props.image} />
        </CardActionArea>
      </Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Zoom in={open}>
          <Card style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%)",
            width: "700px",
            maxWidth: "90%",
            outline: "0"
          }}>
            <CardContent>
              <Typography variant="h5">{props.title}</Typography>
              <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
              {props.children}
            </CardContent>
            <Button onClick={() => setOpen(false)} style={{ width: "100%" }}><CloseIcon />CLOSE</Button>
          </Card>
        </Zoom>
      </Modal>
    </Fragment>
  );
}

export default function Projects() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <p id="Projects" />
      <Typography variant="h4" marked="center" align="center" component="h2">
        PROJECTS
      </Typography>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="STREAM" subtitle="オンラインプログラミング教室/英語教室" image={StreamCoverImage}>
          <Typography variant="body1">
            4つのワクワクする能力（想像・探求・実装・表現）を分野を横断して学ぶことで、自発性、創造性、判断力、問題解決力を育てます。
          </Typography>
          <Typography variant="body1">
            学生講師（＝少し年上のお兄ちゃん、お姉ちゃん）と「ものづくり」を経験し、学びながら、これらの能力を身につけてみませんか。
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={Stream2Image} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://stream.gakuseiguild.co.jp"
              sx={{ mt: 2, textTransform: "none" }}
            >
              公式ウェブサイト
            </Button>
          </Box>
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="未来ゴミ箱 cocomo" subtitle="自律移動ゴミ箱ロボット" image={MiraiGomibakoCoverImage}>
          <Typography variant="body1">
            数々のハッカソンで受賞実績のある「未来ゴミ箱」から着想を得たロボットです．
            商業施設やイベント会場での自律移動や，様々なデモンストレーションを目指して開発中です．
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={MiraiGomibakoAbstImage} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://cocomo.gakuseiguild.co.jp"
              sx={{ mt: 2, textTransform: "none" }}
            >
              公式ウェブサイト
            </Button>
          </Box>
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="monaca" subtitle="省電力・小型なIoTデバイスとデータ解析" image={monacaCoverImage}>
          <Typography variant="body1">
            IoTサービス「monaca」は，省電力・小型なIoTデバイスで5-10年の電池駆動で様々な場所でのセンシングに対応します．
            また，収集したデータの可視化，解析についても現在開発中です．
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={monacaAbstImage} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://monaca.gakuseiguild.co.jp"
              sx={{ mt: 2, textTransform: "none" }}
            >
              公式ウェブサイト
            </Button>
          </Box>
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="STREAM LAB" subtitle="プログラミングでオンライン対戦、人材マッチング" image={StreamLabImage}>
          <Typography variant="body1">
            「STREAM LAB」は現在開発中のプログラミング学習プラットフォームです．
            簡単なプログラムを書いてキャラクターを動かし，友達の書いたプログラムで動くキャラクターと対戦させて遊ぶことができます．
            小学校高学年からプログラムに自信のある大人まで皆が楽しめます．
            ゲームアルゴリズムを仲間と対戦しながら育てることで，プログラミングの基礎から応用までを学べます．
            また，対戦成績を数値化し，その数値をベースに人材マッチングするサービスを提供予定です．
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={StreamLabGif} />
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="その他" subtitle="受託開発など" image={OtherImage}>
          <Typography variant="body1">
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={OtherImage} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://gmsck.seya.work/"
              sx={{ mt: 2, textTransform: "none" }}
            >
              例: GMailSend Address Checker公式サイト
            </Button>
          </Box>
        </WorkCard>
      </Box>
      {/* <Box sx={{ mt: 3 }}>
        <WorkCard title="講演" subtitle="今注目されているSTEAM教育とは" image={ProfileImage}>
          <Typography variant="body1">
            「STEAM教育」は近年では文科省や経産省からも注目される教育概念です。
            私たちは自らの立場で何ができるのか、これからの日本の教育は一緒に考え共創していく必要があることをお伝えします。
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={VCTImage} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://www.sbrain.co.jp/keyperson/K-18824.htm"
              sx={{ mt: 2 }}
            >
              講演の詳細（外部リンク）
            </Button>
          </Box>
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="とよたフェスティバーレ" subtitle="誰も傍観者にさせないアートの祭典" image={FestivaleImage}>
          <Typography variant="body1">
            プロじゃない人でも表現することに挑戦する、その勇気を互いに応援し合う場を作るアートプロジェクト。
            表現とは何か、参加とは何かを問いかけ、主体的に考えてもらいます。
            傍観者であることに慣れすぎた我々を表現者にする仕組みを作ることで、自ら作り出す喜びを感じる人を増やします。
          </Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>
            2019年 第一回 桜城址公園
          </Typography>
          <Typography variant="h6">
            2020年 第二回 豊田スタジアム (感染症対策のためオンライン開催)
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={FestivaleImage} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://www.youtube.com/watch?v=8uqtZGqJEKs"
              sx={{ mt: 2 }}
            >
              過去の配信（YouTube）
            </Button>
          </Box>
        </WorkCard>
      </Box> */}
    </Container >
  );
}
