import * as React from 'react';
import AnchorLink from "react-anchor-link-smooth-scroll";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from "@mui/material/Link";
import logoengImg from "../../assets/logo_eng.png"

const button_color = "black";
const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 1,
  mr: 1
};

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }} style={{ color: "#000000", backgroundColor: "#ffffffaa" }} elevation={3}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box style={{ display: "flex", textAlign: "center" }}>
          <AnchorLink href='#Top' offset={1000} style={{ display: "flex", textAlign: "center", alignItems: "center" }}>
            <img src={logoengImg} alt="logo" height="60%" />
          </AnchorLink>
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end", mr: 0 }}>
          <Box
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <AnchorLink href='#About' style={{ textDecoration: "none" }}>
              <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                ABOUT
              </Typography>
            </AnchorLink>
            <AnchorLink href='#Projects' style={{ textDecoration: "none" }}>
              <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                PROJECTS
              </Typography>
            </AnchorLink>
            <AnchorLink href='#Member' style={{ textDecoration: "none" }}>
              <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                MEMBERS
              </Typography>
            </AnchorLink>
            <AnchorLink href='#Company' style={{ textDecoration: "none" }}>
              <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                COMPANY
              </Typography>
            </AnchorLink>
            <Link
              variant="h5"
              underline="none"
              target="_blank"
              href="https://forms.gle/E79EUiQEsWrbzZV4A"
              sx={{ ...rightLink, color: button_color }}
            >
              CONTACT
            </Link>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <AnchorLink href='#About' style={{ textDecoration: "none" }}>
                  <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                    ABOUT
                  </Typography>
                </AnchorLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <AnchorLink href='#News' style={{ textDecoration: "none" }}>
                  <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                    NEWS
                  </Typography>
                </AnchorLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <AnchorLink href='#Projects' style={{ textDecoration: "none" }}>
                  <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                    PROJECTS
                  </Typography>
                </AnchorLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <AnchorLink href='#Member' style={{ textDecoration: "none" }}>
                  <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                    MEMBERS
                  </Typography>
                </AnchorLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <AnchorLink href='#Company' style={{ textDecoration: "none" }}>
                  <Typography variant="h5" marked="center" component="h2" sx={{ ...rightLink, color: button_color }}>
                    COMPANY
                  </Typography>
                </AnchorLink>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  variant="h5"
                  underline="none"
                  target="_blank"
                  href="https://forms.gle/E79EUiQEsWrbzZV4A"
                  sx={{ ...rightLink, color: button_color }}
                >
                  CONTACT
                </Link>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
