import * as React from "react";
import OMMF2022Works from "./modules/views/OMMF2022Works";
import AppFooter from "./modules/views/AppFooter";

function OMMF2022() {
  return (
    <React.Fragment>
      <OMMF2022Works />
      <AppFooter />
    </React.Fragment>
  );
}
export default OMMF2022;
