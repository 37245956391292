import { BrowserRouter, Route } from 'react-router-dom';
import Home from './Home';
import OMMF2022 from './OMMF2022';
import withRoot from "./modules/withRoot";

function App() {
	return (
		<BrowserRouter>
			<Route exact path="/" component={Home} />
			<Route path="/OMMF2022" component={OMMF2022} />
		</BrowserRouter>
	);
}

export default withRoot(App);