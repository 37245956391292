import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from "../components/Typography";

const company_rows = [
  { first: "社名", second: "合同会社　学生ギルド" },
  { first: "設立年月日", second: "2020年12月24日" },
  { first: "所在地", second: "〒471-0028\n愛知県豊田市神明町１丁目２４" },
  { first: "資本金", second: "1,000,000円" },
  { first: "事業内容", second: "(1) 学習教室の経営\n(2) イベントの運営\n(3) ハードウェア及びソフトウェアの開発、販売及びそれらに付随するサービス\n(4) 教育教材の販売\n(5) 動画像処理、言語処理を含む情報処理技術の研究、開発、販売\n(6) コミュニティサロンの運営\n(7) 教育教材のリース事業\n(8) 前各号に附帯関連する一切の事業" },
  { first: "代表", second: "鈴木 美苗" },
];

function Company() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p id="Company" />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          COMPANY
        </Typography>
        <Box>
          <Box width="100%" style={{ margin: "auto" }}>
            <Table size="small" sx={{ mt: 3 }}>
              <TableBody>
                {company_rows.map((row) => {
                  return (
                    <TableRow key={row.first}>
                      <TableCell align="left" style={{ width: "1px", whiteSpace: "nowrap" }}>{row.first}</TableCell>
                      <TableCell align="left" style={{ whiteSpace: "pre-wrap" }}>{row.second}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Company;
