import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "../components/Typography";
import AboutImage from "../../assets/about.png";

function About() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p id="About" />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          ABOUT
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
            <Box>
              <Box width="100%" style={{ margin: "auto" }}>
                <Typography variant="h5" mb={2}>
                  すべての人に創造性を
                </Typography>
                <Typography variant="body1" mb={1}>
                  学生ギルドは、ロボット開発、受託開発、オンライン教室運営に取り組むベンチャーです。
                </Typography>
                <Typography variant="body1" mb={1}>
                  ロボット、AI、Web、教育など、様々なバックグラウンドを持つメンバーが、技術開発や教育に取り組んでいます。
                </Typography>
                <Typography variant="body1" mb={1}>
                  私たちは、急速に技術が発展する現代において、技術のみならず、教育手法を探求していくことが重要だと考えています。
                  先端的な技術開発をベースに、幅広い層に向けた教育手法を開発・実践することで、より多くの人に「ものづくり」の選択肢を示し、継続的で豊かなテクノロジーの発展に貢献します。
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} mt={3} order={{ xs: 1, sm: 2 }}>
            <div style={{ textAlign: "center" }}>
              <img src={AboutImage} width="80%" alt="" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
