import * as React from "react";
import About from "./modules/views/About";
import Projects from "./modules/views/Projects";
import Members from "./modules/views/Members";
import Company from "./modules/views/Company";
import AppFooter from "./modules/views/AppFooter";
import Top from "./modules/views/Top";
import News from "./modules/views/News";
import ResponsiveAppBar from "./modules/views/ResponsiveAppBar";

function Index() {
  return (
    <React.Fragment>
      <Top />
      <About />
      <Projects />
      <Members />
      <Company />
      <AppFooter />
      <ResponsiveAppBar />
    </React.Fragment>
  );
}
//aaa<ProductValues />
export default Index;
