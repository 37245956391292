import * as React from "react";

import TopLayout from "./TopLayout";
import logoImage from "../../assets/logo.png";

export default function Top() {
  return (
    <TopLayout>
      <p id="Top" />
      <img color="inherit" src={logoImage} style={{ maxWidth: "80%", maxHeight: "80%" }} alt="" />
    </TopLayout>
  );
}
