import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <React.Fragment>
      <Box
        color="inherit"
        href="https://gakuseiguild.co.jp/"
        position="center"
        height="15vh"
      >
        <div style={{ textAlign: "center" }}>
          {"© "}
          <Link
            sx={{ mb: 3 }}
            color="inherit"
            href="https://gakuseiguild.co.jp/"
            position="center"
          >
            GakuseiGuild LLC
          </Link>{" "}
          {new Date().getFullYear()}
        </div>
      </Box>
    </React.Fragment>
  );
}

export default function AppFooter() {
  return <Copyright />;
}
