import * as React from "react";
import { Fragment } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Zoom from "@mui/material/Zoom";

import Typography from "../components/Typography";
import MiraiGomibakoCoverImage from "../../assets/robot.jpg"
import MiraiGomibakoAbstImage from "../../assets/miraigomibako_abstract.jpg"
import monacaCoverImage from "../../assets/monaca_device.png"
import monacaAbstImage from "../../assets/monaca_abstract.jpg"
import StreamCoverImage from "../../assets/stream_cover.jpg"
import StreamAbstImage from "../../assets/stream.png"

function WorkCard(props) {
  const root_style = { display: "flex", width: "100%", height: "12em" };
  const style = props.style === null ? root_style : Object.assign(root_style, props.style);

  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Card onClick={() => setOpen(true)} raised={true} style={style}>
        <CardActionArea style={{ display: "flex", justifyContent: "left" }}>
          <CardContent align="left" style={{ flex: "1 0 50%" }}>
            <Typography variant="h5">{props.title}</Typography>
            <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
          </CardContent>
          <CardMedia component="img" style={{ minWidth: "50%", minHeight: "100%" }} src={props.image} />
        </CardActionArea>
      </Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Zoom in={open}>
          <Card style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%)",
            width: "700px",
            maxWidth: "90%",
            outline: "0"
          }}>
            <CardContent>
              <Typography variant="h5">{props.title}</Typography>
              <Typography variant="subtitle1" color="textSecondary">{props.subtitle}</Typography>
              {props.children}
            </CardContent>
            <Button onClick={() => setOpen(false)} style={{ width: "100%" }}><CloseIcon />CLOSE</Button>
          </Card>
        </Zoom>
      </Modal>
    </Fragment>
  );
}

export default function OMMF2022Works() {
  return (
    <Container component="section" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h5" marked="none" align="center" component="" sx={{ mb: 0 }}>
        Ogaki Mini Maker Faire 2022
      </Typography>
      <Typography variant="h4" marked="none" align="center" component="" sx={{ mb: 4 }}>
        "STREAM Laboratory"
      </Typography>
      <Typography variant="body2" marked="center" align="left" component="">
        合同会社学生ギルドでは，現在開発中の自律移動ロボット「未来ゴミ箱」やIoTシステム「monaca」など，ゴミ問題への技術的取り組みを展示予定です．
      </Typography>
      <Typography variant="body2" marked="center" align="left" component="">
        また，弊社の運営するオンラインプログラミング教室「STREAM」のワークショップでは，「作るを学ぼう，学生と一緒に。」をテーマにロボコン・ハッカソン好きの学生とともに歩むプログラミング学習や，弊社独自のプログラミング学習・対戦プラットフォーム「STREAM LAB」を使用したPythonプログラミングを体験していただくことができます．
      </Typography>
      <Typography variant="h5" marked="center" align="center" component="h2" sx={{ mt: 4 }}>
        展示予定（クリックで詳細を表示）
      </Typography>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="未来ゴミ箱" subtitle="自律移動ゴミ箱ロボット" image={MiraiGomibakoCoverImage}>
          <Typography variant="body1">
            数々のハッカソンで受賞した「未来ゴミ箱」が事業化し、バージョンアップして帰ってきました。
            大型化し完成度の高まったゴミ箱ロボットをぜひご覧ください。
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={MiraiGomibakoAbstImage} />
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="monaca" subtitle="省電力・小型なデバイスと解析サービス" image={monacaCoverImage}>
          <Typography variant="body1">
            IoTサービス「monaca」では，省電力・小型なIoTデバイスを開発し，5-10年の電池駆動で様々な場所でのセンシングに対応します．
            また，収集したデータは機械学習・探索等の技術を用いて解析されます．（開発中）
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={monacaAbstImage} />
        </WorkCard>
      </Box>
      <Box sx={{ mt: 3 }}>
        <WorkCard title="STREAM ワークショップ" subtitle="学生と学ぶ完全オンラインプログラミング教室" image={StreamCoverImage}>
          <Typography variant="body1">
            弊社独自のプログラミング学習プラットフォーム「STREAM LAB」を体験していただきます．
            簡単なプログラムを書いてキャラクターを動かし，友達の書いたプログラムで動くキャラクターと対戦させて遊ぶことができます．
            小学校高学年からプログラムに自信のある大人まで皆が楽しめます．
            ゲームアルゴリズムを仲間と対戦しながら育てることで，プログラミングの基礎から応用までを学べます．
          </Typography>
          <CardMedia component="img" style={{ minWidth: "80%", minHeight: "100%" }} src={StreamAbstImage} />
          <Box style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href="https://stream.gakuseiguild.co.jp"
              sx={{ mt: 2 }}
            >
              公式ウェブサイト
            </Button>
          </Box>
        </WorkCard>
      </Box>
    </Container >
  );
}
